import React from 'react'
import { useInView } from 'react-intersection-observer'

import { getCampaignKeyMetrics, getCampaignCharts } from 'Services/Analytics/campaign-analytics'
import CustomerInsights from './CustomerInsights'
import BarLineChartTemplate from '../../Components/BarLineChart.Template'
import KeyMetricsTemplate from '../../Components/KeyMetrics.Template'
import PivotTableTemplate from '../../Components/PivotTable.Template'
import { PI_DIMENSION, PI_METRIC } from 'Pages/Analytics/constants'
import { CampaignPivotTypes } from 'Services/Analytics/Pivot.service'
import './index.scss'

const Body = () => {
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })

  return (
    <div className="my-4">
      <div
        style={{ minHeight: 172 }}
        ref={ref1}
        className="bg-white p-4 Campaign360-KeyMetrics-Wrapper"
      >
        <div className="mb-4 font-semibold ">Key Metrics</div>
        {ref1InView && <KeyMetricsTemplate handleDataFunction={getCampaignKeyMetrics} />}
      </div>
      {/* <div style={{ minHeight: 200 }} ref={ref2} className="bg-white p-4 mt-4">
        
      </div> */}
      <div style={{ minHeight: 200 }} ref={ref7} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Product Insight</div>
        {ref7InView && (
          <PivotTableTemplate
            currentPage="campaign360"
            type={CampaignPivotTypes.ProductInsight}
            defaultDimensions={PI_DIMENSION}
            defaultMetrics={PI_METRIC}
          />
        )}
      </div>
      {/* <div ref={ref8} className="bg-white mt-4">
        <div className="mb-4  p-4 font-semibold">Customer Insight in this Campaign</div>
        {ref8InView && <CustomerInsights />}
      </div> */}
      {/* <div className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Audience Funnel</div>
      </div> */}
      <div ref={ref9} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref9InView && <PivotTableTemplate currentPage="campaign360" />}
      </div>
    </div>
  )
}

export default Body
