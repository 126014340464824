import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Skeleton, Empty } from 'antd'
import axios from 'axios'

import CardListPlaceholder from 'Components/Placeholders/CardList'
import ReactPlaceholder from 'react-placeholder'
import ProductItem, { ProductItemType } from 'Pages/Analytics/Components/ProductItem'
import { FiltersType } from 'AnalyticsTypes'
import useFilters from './FiltersHeader/useFilters'

const ProductList = ({
  barcode,
  handleDataFunction,
  code,
}: {
  barcode?: string
  code: string
  handleDataFunction: (filters: FiltersType) => Promise<any>
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [products, setProducts] = useState<ProductItemType[] | []>([])
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const handleKeyMetrics = async () => {
      setIsLoading(true)
      try {
        setError('')
        const resp = await handleDataFunction({
          barcode,
          id,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          type: code,
          cancelTokenSource,
        })

        setProducts(resp.data)
      } catch (err: any) {
        setError(err?.response?.data?.detail || 'Some thing went wrong')
      }
      setIsLoading(false)
    }

    handleKeyMetrics()

    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [barcode, id, queryObject.from_date, queryObject.to_date, handleDataFunction, code])

  const renderProducts = useMemo(() => {
    if (!products?.length) return <Empty />
    return products.map((product, index) => (
      <div className="mb-3" key={index}>
        <ProductItem
          key={index}
          product={product}
          isUnit={code === 'cart_abandon_products' || code === 'recent_viewed_products'}
        ></ProductItem>
      </div>
    ))
  }, [products])

  if (error) return <Alert message={error} type="error" />

  return (
    <ReactPlaceholder
      className="w-full"
      showLoadingAnimation
      ready={!isLoading}
      customPlaceholder={<CardListPlaceholder />}
    >
      <div className="w-full" style={{ minWidth: 250 }}>
        {renderProducts}
      </div>
    </ReactPlaceholder>
  )
}

export default ProductList
