import React, { useEffect, useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useParams } from 'react-router-dom'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import { getCampaignDetail } from 'Services/campaigns'

import FiltersHeader from '../Components/FiltersHeader'
import Body from './Body'

const Campaign360 = () => {
  const { id } = useParams()
  const history = useHistory()
  const [campaignName, setCampaignName] = useState('')

  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  useEffect(() => {
    const handleCampaignName = async () => {
      try {
        const resp = await getCampaignDetail(id)
        setCampaignName(resp.data.name)
      } catch (err: any) {
        message.error('Something went wrong')
      }
    }

    if (id) {
      setBreadCrumb([
        {
          title: 'Marketing Campaigns',
          path: `/analytics/marketing-campaigns?${history.location.search}`,
        },
        { title: 'Marketing 360', path: `/analytics/campaign360/${id}` },
      ])
      handleCampaignName()
    }
  }, [setBreadCrumb, id, history.location.search])

  return (
    <div className="px-8 py-7 w-full AnalyticsWrapper">
      <span className="text-3xl font-semibold">{campaignName}</span>
      <div className="mt-8">
        <div>
          <FiltersHeader hideDimensions />
        </div>
        <Body />
      </div>
    </div>
  )
}

export default Campaign360
