import React from 'react'

import BarChartTemplate from '../../../Components/BarChart.Template'
import MultiDoughnutChartTemplate from '../../../Components/MultiDoughnutChart.Template'
import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'

const ChartRow5 = () => {
  return (
    <>
      <div className="p-4 col-span-1 shadow-div_r">
        <div className="mb-4 font-semibold">Cluster</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <MultiDoughnutChartTemplate
            chartCode="cluster"
            handleDataFunction={getCustomerOverviewCharts}
          />
        </div>
      </div>
      <div className="p-4 col-span-2">
        <div className="mb-4 font-semibold">Cluster</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="cluster_by_time"
            handleDataFunction={getCustomerOverviewCharts}
            isStacked
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow5
