import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { thousandsSeparator } from 'Utils/kFormatter'

interface DataType {
  class: string
  target_customer_cnt: number
  purchased_customer_cnt: number
  purchase_rate: number
  target_customer_revenue: number
  redeem_customer_cnt: number
  redeem_rate: number
  redeem_customer_revenue: number
  total_sms_cost: number
  voucher_cost: number
  roi: number
}
export const columns: ColumnsType<DataType> = [
  {
    title: 'Class',
    dataIndex: 'class',
    key: 'class',
    width: 65,
    // fixed: 'left',
  },
  {
    title: 'target_customer_cnt',
    dataIndex: 'target_customer_cnt',
    key: 'target_customer_cnt',
    width: 181,
    // fixed: 'left',
    sorter: {
      compare: (a, b) => a.target_customer_cnt - b.target_customer_cnt,
      multiple: 1,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },

  {
    title: 'purchase_customer_cnt',
    dataIndex: 'purchased_customer_cnt',
    key: 'purchased_customer_cnt',
    width: 181,
    sorter: {
      compare: (a, b) => a.purchased_customer_cnt - b.purchased_customer_cnt,
      multiple: 2,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'redeem_customer_cnt',
    dataIndex: 'redeem_customer_cnt',
    key: 'redeem_customer_cnt',
    width: 181,
    sorter: {
      compare: (a, b) => a.redeem_customer_cnt - b.redeem_customer_cnt,
      multiple: 5,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'purchase_rate',
    dataIndex: 'purchase_rate',
    key: 'purchase_rate',
    width: 181,
    sorter: {
      compare: (a, b) => a.purchase_rate - b.purchase_rate,
      multiple: 3,
    },
    render: (value: number) => <div>{value?.toFixed(0)}%</div>,
  },
  {
    title: 'redemption_rate',
    dataIndex: 'redeem_rate',
    key: 'redeem_rate',
    width: 181,
    sorter: {
      compare: (a, b) => a.redeem_rate - b.redeem_rate,
      multiple: 6,
    },
    render: (value: number) => <div>{value?.toFixed(0)}%</div>,
  },
  {
    title: 'target_customer_revenue',
    dataIndex: 'target_customer_revenue',
    key: 'target_customer_revenue',
    width: 181,
    sorter: {
      compare: (a, b) => a.target_customer_revenue - b.target_customer_revenue,
      multiple: 4,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },

  {
    title: 'redeem_customer_revenue',
    dataIndex: 'redeem_customer_revenue',
    key: 'redeem_customer_revenue',
    width: 210,
    sorter: {
      compare: (a, b) => a.redeem_customer_revenue - b.redeem_customer_revenue,
      multiple: 7,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'sms_cost',
    dataIndex: 'total_sms_cost',
    key: 'total_sms_cost',
    width: 112,
    sorter: {
      compare: (a, b) => a.total_sms_cost - b.total_sms_cost,
      multiple: 8,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'voucher_cost',
    dataIndex: 'voucher_cost',
    key: 'voucher_cost',
    width: 181,
    sorter: {
      compare: (a, b) => a.voucher_cost - b.voucher_cost,
      multiple: 9,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'roi',
    dataIndex: 'roi',
    key: 'roi',
    width: 112,
    sorter: {
      compare: (a, b) => a.roi - b.roi,
      multiple: 10,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
]
