import React from 'react'
import { Typography, Tooltip, Badge } from 'antd'

import { capitalizeFirstLetter } from 'Utils'
import MemberShipBadge from 'Components/MemberShipBadge'

const { Text } = Typography

export const CUSTOMER_LIST_COLUMNS = [
  'name',
  'customer_id',
  'tracking_id',
  'segments',
  'membership',
  'ltv',
  'life_cycle',
  'cluster',
  'trust_score',
  'buying_score',
  'add_to_cart_score',
  'churn_score',
  'high_ltv_score',
]
const computeColor = (input: number) => {
  if (input <= 0.5) {
    return '#F5222D'
  } else if (input > 0.5 && input <= 0.7) {
    return '#FAAD14'
  } else if (input > 0.7 && input <= 1) {
    return '#52C41A'
  }
}

export const COLUMNS = [
  ...CUSTOMER_LIST_COLUMNS.map((column, index, records) => {
    if (column === 'name') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        sorter: true,
        width: 200,
        align: 'left',
        render: (customer) => (
          <Tooltip title={customer}>
            <Text className="customer-title" strong>
              {customer}
            </Text>
          </Tooltip>
        ),
        fixed: 'left',
      }
    }
    if (column === 'customer_id') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        sorter: true,
        width: 200,
        align: 'left',
      }
    }

    if (column === 'tracking_id') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        width: 150,
        render: (trackingId: string) => {
          return <span>{trackingId ? trackingId : 'N/A'}</span>
        },
      }
    }

    if (column === 'segments') {
      return {
        title: 'Segment',
        dataIndex: column,
        width: 277,
        render: (segments: string[]) => {
          return (
            <Tooltip
              title={segments?.map((item) => (
                <div>{item}</div>
              ))}
              className="text-left flex flex-col gap-1"
            >
              {segments?.slice(0, 5)?.map((segment, index) => {
                if (index === 4) {
                  return (
                    <div className="line-clamp-1 text-blue-600 font-semibold">{`+ ${
                      segments?.length - 4
                    } others`}</div>
                  )
                }
                return (
                  <div
                    className="line-clamp-1 px-4 py-1 font-semibold bg-gray-200 border"
                    style={{ borderRadius: 16, width: 'fit-content' }}
                  >
                    {segment}
                  </div>
                )
              })}
            </Tooltip>
          )
        },
      }
    }

    if (column === 'cluster') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        sorter: true,
        width: 150,
        render: (cluster: string) => {
          return <span>{cluster ? cluster : 'N/A'}</span>
        },
      }
    }

    if (column === 'ltv') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (ltv: string) => {
          return <span>{ltv ? ltv : 'N/A'}</span>
        },
      }
    }

    if (column === 'life_cycle') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (life_cycle: string) => {
          return <span>{life_cycle ? life_cycle : 'N/A'}</span>
        },
      }
    }

    if (column === 'membership') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (membership: string, record: any) => {
          return (
            <div className="flex justify-center">
              <MemberShipBadge membership={membership} />
            </div>
          )
        },
      }
    }
    if (column === 'trust_score') {
      return {
        title: 'Trust score',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value} />
            </div>
          )
        },
      }
    }
    if (column === 'buying_score') {
      return {
        title: 'Buying',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value} />
            </div>
          )
        },
      }
    }
    if (column === 'add_to_cart_score') {
      return {
        title: 'Add to cart',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value} />
            </div>
          )
        },
      }
    }
    if (column === 'churn_score') {
      return {
        title: 'Churn',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value} />
            </div>
          )
        },
      }
    }
    if (column === 'high_ltv_score') {
      return {
        title: 'High LTV',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value} />
            </div>
          )
        },
      }
    }
    return {
      title: capitalizeFirstLetter(column),
      dataIndex: column,
      width: 150,
      sorter: true,
    }
  }),
]

export const STATUS: { label: string; value: string }[] = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const IS_ACTIVE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const CHANNELS = [
  { label: 'Published', value: 'sas' },
  { label: 'Draft', value: 'init' },
]
export const TYPES = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const DATE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
