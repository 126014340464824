import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Empty } from 'antd'
import axios from 'axios'

import { PieChartPlaceholder } from 'Components/Placeholders/Charts'
import ReactPlaceholder from 'react-placeholder'
import { COLOR_PALLETTE } from 'Utils/constants'
import PieChart from 'Components/Charts/PieChart/NewPieChart'
import Legends from 'Components/Charts/Legends'
import useFilters from './FiltersHeader/useFilters'
import { MultiDoughnutChartType, FiltersType } from 'AnalyticsTypes'
import { DataSourceContext } from 'Pages/Analytics/Products/Overview/index'

import kFormatter from 'Utils/kFormatter'

const MultiDoughnutChartTemplate = ({
  barcode,
  handleDataFunction,
  chartCode,
  className = '',
  options = {},
}: {
  barcode?: string
  chartCode: string
  className?: string
  handleDataFunction: (filters: FiltersType) => Promise<any>
  options?: { percentToFixed?: number }
}) => {
  const dataSource = React.useContext(DataSourceContext)
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [chart, setChart] = useState<MultiDoughnutChartType[] | undefined>(undefined)
  const [firstTotal, setFirstTotal] = useState<any>()
  const [secondTotal, setSecondTotal] = useState<any>()

  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        const resp = await handleDataFunction({
          barcode: id,
          id,
          type: chartCode,
          frequency: queryObject?.frequency?.toString() || defaultFilters.frequency,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          source: dataSource?.toString() || '',
          cancelTokenSource,
        })
        setFirstTotal(resp?.data?.data?.data[0]?.total)
        setSecondTotal(resp?.data?.data?.data[1]?.total)

        setChart(resp?.data?.data?.data)
      } catch (err: any) {
        if (err.message === 'cancelled') {
          return
        }

        setError(err?.response?.data?.detail || 'No data available')
      }
      setIsLoading(false)
    }

    fetchData()
    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    barcode,
    id,
    chartCode,
    handleDataFunction,
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    dataSource,
  ])
  const renderChart = useMemo(() => {
    if (!chart?.length) return <Empty />

    const computeTotal = (object) => {
      return Object.keys(object).reduce((accum, key) => {
        accum += object[key]
        return accum
      }, 0)
    }

    return (
      <div>
        <div className="flex justify-between items-center">
          <div style={{ width: '45%' }}>
            <PieChart
              centerText={`${
                firstTotal ? kFormatter(firstTotal) : kFormatter(computeTotal(chart[0]?.values))
              } ${chart[0]?.label}`}
              data={chart[0]?.values}
              isVertical={true}
              showLegend={false}
              options={options}
            />
          </div>
          <div className="spacing" style={{ width: 5 }}></div>
          <div style={{ width: '45%' }}>
            <PieChart
              centerText={`${
                secondTotal ? kFormatter(secondTotal) : kFormatter(computeTotal(chart[1]?.values))
              } ${chart[1]?.label}`}
              data={chart[1]?.values}
              isVertical={true}
              showLegend={false}
              options={options}
            />
          </div>
        </div>
        <div className="mt-2">
          <Legends
            legends={Object.keys(chart[0]?.values)?.map((legend, index) => ({
              label: legend,
              color: COLOR_PALLETTE[index],
              shape: 'rounded',
            }))}
          ></Legends>
        </div>
      </div>
    )

    // return <DoughnutTwice className={className} data={chart}></DoughnutTwice>
  }, [chart])

  if (error) return <Alert message={error} type="error" />

  return (
    <ReactPlaceholder
      className="w-full"
      showLoadingAnimation
      ready={!isLoading}
      customPlaceholder={<PieChartPlaceholder isMulti />}
    >
      <div className="w-full">{renderChart}</div>
    </ReactPlaceholder>
  )
}

export default MultiDoughnutChartTemplate
