import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Skeleton, Empty } from 'antd'
import axios from 'axios'

import { PieChartPlaceholder } from 'Components/Placeholders/Charts'
import ReactPlaceholder from 'react-placeholder'
import PieChart from 'Components/Charts/PieChart/NewPieChart'
import useFilters from './FiltersHeader/useFilters'

import { FiltersType } from 'AnalyticsTypes'
import kFormatter from 'Utils/kFormatter'
import { DataSourceContext } from 'Pages/Analytics/Products/Overview/index'

const CHART = {
  'Giày sandal': 10,
  'Váy ngắn': 20,
  'Quần jeans': 35,
  'Áo xách nách': 42,
}

const DoughnutChartWrapper = ({
  barcode,
  handleDataFunction,
  chartCode,
  unit = 'item',
  className = '',
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
  barcode?: string
  chartCode: string
  isStacked?: boolean
  isHorizontal?: boolean
  hideLegends?: boolean
  unit?: string
  className?: string
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()
  const dataSource = React.useContext(DataSourceContext)

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [chart, setChart] = useState<{ [key: string]: number } | undefined>(undefined)
  const [total, setTotal] = useState<any>()
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        // setChart(CHART)
        const resp = await handleDataFunction({
          barcode: id,
          id,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          type: chartCode,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          cancelTokenSource,
          source: dataSource?.toString() || '',
        })
        setTotal(resp?.data?.data?.total)

        const formattedData = resp?.data?.data?.data.reduce((prev, current) => {
          const result = {
            ...prev,
            [current.label]: parseInt(current.value) || parseFloat(current.value),
          }
          return result
        }, {})

        setChart(formattedData)
      } catch (err: any) {
        if (err.message === 'cancelled') return
        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'No data available'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    barcode,
    id,
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    chartCode,
    handleDataFunction,
    dataSource,
  ])
  const renderChart = useMemo(() => {
    const totalCount: any =
      chart && Object.keys(chart).reduce((prev, currentKey) => prev + chart[currentKey], 0)

    if (!chart) return <Empty />
    return (
      <PieChart
        className={className}
        isVertical
        data={chart}
        centerText={`${total ? kFormatter(total) : kFormatter(totalCount)} ${unit}`}
      />
    )
  }, [chart, unit, className])

  if (error) return <Alert message={error} type="error" />

  return (
    <ReactPlaceholder
      className="w-full"
      showLoadingAnimation
      ready={!isLoading}
      customPlaceholder={<PieChartPlaceholder />}
    >
      <div className="w-full" style={{ height: '90%' }}>
        {renderChart}
      </div>
    </ReactPlaceholder>
  )
}

export default DoughnutChartWrapper
