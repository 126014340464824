import React from 'react'
import AreaChartTemplate from '../../../Components/AreaChart.Template'

import BarChartTemplate from 'Pages/Analytics/Components/BarChart.Template'

import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'

const ChartRow6 = () => {
  return (
    <>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold">Channel Type by Time</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <AreaChartTemplate
            chartCode="sale_channel_by_time"
            handleDataFunction={getCustomerOverviewCharts}
          />
        </div>
      </div>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold">Customer Type by Time</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <AreaChartTemplate
            chartCode="customer_type_by_time"
            handleDataFunction={getCustomerOverviewCharts}
          />
        </div>
      </div>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold">Sale Campaign</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="sale_campaign"
            hideLegends
            isHorizontal
            max={'auto'}
            isPercentage={false}
            handleDataFunction={getCustomerOverviewCharts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow6
