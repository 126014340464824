import React, { useState, useRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import {
  getCustomer360KeyMetrics,
  getCustomer360Charts,
  getCustomer360Products,
} from 'Services/Analytics/customer-analytics'

import KeyMetricsTemplate from '../../Components/KeyMetrics.Template'
import ProductListTemplate from '../../Components/ProductList.Template'
import BarChartTemplate from '../../Components/BarChart.Template'
import BarLineChartTemplate from '../../Components/BarLineChart.Template'
import DoughnutChartTemplate from '../../Components/DoughnutChart.Template'

import Interactions from './Interactions'
import Activities from './Activities'
import Header from './Header'

import './index.scss'

const Body = () => {
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })

  const [height, setHeight] = useState(736)
  const myref = useRef<any>()
  const getListSize = () => {
    const newHeight = myref?.current?.clientHeight
    setHeight(newHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', getListSize)
  })

  return (
    <div>
      <div ref={myref}>
        <div style={{ minHeight: 300 }} ref={ref1} className="flex gap-4 overflow-hidden">
          {ref1InView && (
            <>
              <div className="w-2/3" style={{ paddingBottom: 99999, marginBottom: -99999 }}>
                <div className="bg-white p-4 mb-4">
                  <Header />
                </div>
                <div className="bg-white p-4 customer-360-metrics">
                  <div className="mb-4 font-semibold">
                    Values{' '}
                    <span className="text-secondary_text text-sm font-normal">
                      (Last 12 months)
                    </span>
                  </div>
                  <KeyMetricsTemplate handleDataFunction={getCustomer360KeyMetrics} />
                </div>
              </div>
              <div
                className="bg-white p-4 w-1/3 customer-360-activities"
                style={{
                  paddingBottom: 99999,
                  marginBottom: -99999,
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: height,
                }}
              >
                <div className="mb-4 font-semibold" style={{ flexShrink: 0 }}>
                  Activities{' '}
                  <span className="text-secondary_text text-sm font-normal">
                    (Last 30 activities)
                  </span>
                </div>

                <Activities />
                <div style={{ padding: 2, flexShrink: 0 }}></div>
              </div>
            </>
          )}
        </div>
      </div>

      <div style={{ minHeight: 500 }} ref={ref2} className="mt-4 grid grid-cols-4 gap-4">
        {ref2InView && (
          <>
            <div className="col-span-3 h-full">
              <div className="grid grid-cols-5 gap-4 h-full">
                <div className="bg-white p-4 col-span-2 customer360-purchased-category">
                  <div className="mb-4 font-semibold ">Purchased Category</div>
                  <div
                    style={{ height: 500 }}
                    className="w-full overflow-y-scroll overflow-x-hidden"
                  >
                    <DoughnutChartTemplate
                      chartCode="purchased_category"
                      handleDataFunction={getCustomer360Charts}
                    />
                  </div>
                </div>
                <div className="bg-white p-4 col-span-3">
                  <div className="mb-4 font-semibold">Taste & Functional</div>
                  <div style={{ height: 500 }} className="w-full overflow-scroll">
                    <BarChartTemplate
                      chartCode="styles_and_functional"
                      handleDataFunction={getCustomer360Charts}
                      hideLegends
                      isHorizontal
                      max={'auto'}
                      isPercentage={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 col-span-1">
              <div className="mb-4 font-semibold">Interactions</div>
              <div style={{ height: 500 }} className="w-full">
                <Interactions />
              </div>
            </div>
          </>
        )}
      </div>

      <div style={{ minHeight: 300 }} ref={ref3} className="mt-4 grid grid-cols-4 gap-4">
        {ref3InView && (
          <>
            <div className="col-span-3 h-full">
              <div className="grid grid-cols-3 gap-4 h-full">
                <div className="bg-white p-4 col-span-2" style={{ height: 400 }}>
                  <div className="mb-4 font-semibold">Purchase statistics</div>
                  <div style={{ height: 320 }}>
                    <BarLineChartTemplate
                      chartCode="purchased_statistic"
                      handleDataFunction={getCustomer360Charts}
                    />
                  </div>
                </div>
                <div className="bg-white p-4 col-span-1" style={{ height: 400 }}>
                  <div className="mb-4 font-semibold">Cart Abandon</div>
                  <div className="overflow-scroll" style={{ height: '90%' }}>
                    <ProductListTemplate
                      code="cart_abandon_products"
                      handleDataFunction={getCustomer360Products}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 col-span-1" style={{ height: 400 }}>
              <div className="mb-4 font-semibold">Recent View</div>
              <div className="overflow-scroll" style={{ height: '90%' }}>
                <ProductListTemplate
                  code="recent_viewed_products"
                  handleDataFunction={getCustomer360Products}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div style={{ minHeight: 300 }} ref={ref4} className="mt-4 grid grid-cols-3 gap-4">
        {ref4InView && (
          <>
            <div className="bg-white p-4 col-span-1 overflow-hidden" style={{ height: 400 }}>
              <div className="mb-4 font-semibold">Recommend Products</div>
              <div className="overflow-scroll" style={{ height: '90%' }}>
                <ProductListTemplate
                  code="recommended_products"
                  handleDataFunction={getCustomer360Products}
                />
              </div>
            </div>

            <div className="bg-white p-4 col-span-1 overflow-hidden" style={{ height: 400 }}>
              <div className="mb-4 font-semibold">Most View Products</div>
              <div className="overflow-scroll" style={{ height: '90%' }}>
                <ProductListTemplate
                  code="most_viewed_products"
                  handleDataFunction={getCustomer360Products}
                />
              </div>
            </div>

            <div className="bg-white p-4 col-span-1 overflow-hidden" style={{ height: 400 }}>
              <div className="mb-4 font-semibold">Purchased products</div>
              <div className="overflow-scroll" style={{ height: '90%' }}>
                <ProductListTemplate
                  code="purchased_products"
                  handleDataFunction={getCustomer360Products}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Body
