import React, { useState } from 'react'

import StackedBarLine from 'Pages/Analytics/SaleAnalytics/Components/StackedBarLine.Template'
import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useStoreState } from 'easy-peasy'

const ChartRow11 = () => {
  const { storeGroup } = useStoreState((state) => state.dimensions)
  // const [isAudienceFilter, setAudienceFilter] = useState(false)
  const history = useHistory()
  const search = queryString.parse(history.location.search)
  let nameStore = ''
  let isAudienceFilter = false
  const recursionTree = (label: any, data: any) => {
    let result: any = []
    data?.forEach((item) => {
      let childKeys: any = []
      if (item.children) {
        childKeys = recursionTree(label, item.children)
      }
      if (label.includes(item.key)) {
        result.push(...[item.title, ...childKeys])
      } else {
        result.push(...childKeys)
      }
    })

    return result
  }
  if (search?.dimensions) {
    const dimensionList = JSON.parse(search.dimensions.toString())
    dimensionList.forEach((element) => {
      const type = element?.dimension?.segments[0].type
      if (type != 'STORE' && type != 'SALE_CHANNEL') {
        isAudienceFilter = true
      } else {
        if (type == 'STORE') {
          const valueStoreList = element?.dimension?.segments[0].value.split(',')
          if (valueStoreList.length == 1) {
            let getTitle = recursionTree(valueStoreList, storeGroup && storeGroup[0]?.children)
            nameStore = getTitle.toString()
          }
        }
      }
    })
  }
  return (
    <>
      <div className="col-span-3 p-4 bg-white shadow-div_b">
        <div className="mb-4 font-semibold">
          Customer Inflow & Outflow by month{' '}
          {isAudienceFilter ? '' : nameStore ? '( ' + nameStore + ' )' : ''}
        </div>
        <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ height: 500 }}>
          <StackedBarLine
            isNegative={true}
            chartCode="customer_in_out_flow"
            handleDataFunction={getCustomerOverviewCharts}
            hiddenContent={true}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow11
