import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Empty } from 'antd'
import axios from 'axios'

import { BarChartPlaceholder } from 'Components/Placeholders/Charts'
import ReactPlaceholder from 'react-placeholder'
import { BarLineChart } from 'Components/Charts/MixCharts'
import { colors } from 'DesignSystem'
import { BarLineChartType, FiltersType } from 'AnalyticsTypes'
import useFilters from './FiltersHeader/useFilters'
import { DataSourceContext } from 'Pages/Analytics/Products/Overview/index'

export const CHART = {
  labels: ['Aug 1', 'Aug 3', 'Aug 5', 'Aug 7', 'Aug 9', 'Aug 11', 'Aug 13'],
  bars: {
    revenue: { key: 'revenue', title: 'Doanh thu', values: [1633609, 993999, 864569, 894202] },
    cost: { key: 'cost', title: 'Tiền', values: [1533609, 923999, 814569, 891202] },
  },
  lines: {
    orders: { key: 'orders', title: 'Số đơn', values: [17, 18, 7, 29] },
    sessions: { key: 'sessions', title: 'Sessions', values: [10, 7, 20, 34] },
  },
}

export const COLORS = {
  cost: colors.productColor.brand_primary,
  revenue: colors.systemColor.blue_4,
  clicks: colors.systemColor.purple_4,
  order: colors.systemColor.cyan_4,
  session: colors.systemColor.orange_4,
  cr: colors.systemColor.gold_4,
}

const BarLineChartWrapper = ({
  handleDataFunction,
  chartCode,
  isHalf,
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
  chartCode: string
  isHalf?: boolean
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()
  const dataSource = React.useContext(DataSourceContext)

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [chart, setChart] = useState<BarLineChartType | any>()
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        // setChart(CHART)
        const resp = await handleDataFunction({
          barcode: id,
          id: id,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          type: chartCode,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          source: dataSource?.toString() || '',
          cancelTokenSource,
        })
        setChart(resp?.data?.data)
      } catch (err: any) {
        if (err.message === 'cancelled') return

        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'Something went wrong'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    id,
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    chartCode,
    handleDataFunction,
    dataSource,
  ])

  const renderChart = useMemo(() => {
    if (!chart) return <Empty></Empty>
    return (
      <div className={`w-full  h-full`}>
        {chart && <BarLineChart data={chart} opts={{ colors: COLORS }} isHalf={isHalf} />}
      </div>
    )
  }, [chart])

  if (error) return <Alert message={error} type="error" />

  return (
    <ReactPlaceholder
      className="w-full"
      showLoadingAnimation
      ready={!isLoading}
      customPlaceholder={<BarChartPlaceholder />}
    >
      {renderChart}
    </ReactPlaceholder>
  )
}

export default BarLineChartWrapper
