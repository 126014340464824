import React from 'react'

import BarChartTemplate from '../../../Components/BarChart.Template'
import MultiDoughnutChartTemplate from '../../../Components/MultiDoughnutChart.Template'
import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'

const ChartRow2 = () => {
  return (
    <>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 font-semibold">Customer Lifetime Value (LTV)</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <MultiDoughnutChartTemplate
            chartCode="customer_lifetime_value"
            handleDataFunction={getCustomerOverviewCharts}
          />
        </div>
      </div>
      <div className="p-4 col-span-2 shadow-div_b">
        <div className="mb-4 font-semibold">LTV</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="ltv_by_time"
            handleDataFunction={getCustomerOverviewCharts}
            isStacked
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_r">
        <div className="mb-4 font-semibold">LTV x Cluster</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="ltv_x_cluster"
            handleDataFunction={getCustomerOverviewCharts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_r">
        <div className="mb-4 font-semibold">LTV x CLC</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="ltv_x_clc"
            handleDataFunction={getCustomerOverviewCharts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
      <div className="p-4 col-span-1">
        <div className="mb-4 font-semibold">LTV x Sale Channel</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
          <BarChartTemplate
            chartCode="ltv_x_sale_channel"
            handleDataFunction={getCustomerOverviewCharts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow2
