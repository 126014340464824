import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'
import { getCustomerOverviewKeyMetrics } from 'Services/Analytics/customer-analytics'
import LoadingIcon from 'Components/LoadingIcon'

import KeyMetricsTemplate from '../../../Components/KeyMetrics.Template'
import PivotTableTemplate from '../../../Components/PivotTable.Template'

import ChartRow1 from './ChartRow_1'
import ChartRow2 from './ChartRow_2'
import ChartRow3 from './ChartRow_3'
import ChartRow4 from './ChartRow_4'
import ChartRow5 from './ChartRow_5'
import ChartRow6 from './ChartRow_6'
import ChartRow11 from './ChartRow_11'

import PivotRow1 from './PivotRow_1'
import PivotRow2 from './PivotRow_2'

import './index.scss'

const Body = () => {
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })
  const { ref: ref10, inView: ref10InView } = useInView({ triggerOnce: true })
  const { ref: ref11, inView: ref11InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>

  return (
    <div className="my-4">
      <div
        style={{ minHeight: 200 }}
        ref={ref1}
        className="p-4 bg-white customers-analytics-overview-metrics"
      >
        <div className="mb-4 font-semibold">Key Metrics</div>
        {ref1InView && <KeyMetricsTemplate handleDataFunction={getCustomerOverviewKeyMetrics} />}
      </div>
      <div style={{ minHeight: 500 }} ref={ref11} className="mt-4">
        {ref11InView && <ChartRow11 />}
      </div>

      <div style={{ minHeight: 300 }} ref={ref2} className="grid grid-cols-3 gap-4 mt-4">
        {ref2InView && <ChartRow1 />}
      </div>

      <div style={{ minHeight: 600 }} ref={ref3} className="grid grid-cols-3 mt-4 bg-white">
        {ref3InView && <ChartRow2 />}
      </div>

      <div style={{ minHeight: 600 }} ref={ref4} className="grid grid-cols-3 mt-4 bg-white">
        {ref4InView && <ChartRow3 />}
      </div>

      <div style={{ minHeight: 600 }} ref={ref5} className="grid grid-cols-3 mt-4 bg-white">
        {ref5InView && <ChartRow4 />}
      </div>

      <div style={{ minHeight: 300 }} ref={ref6} className="grid grid-cols-3 mt-4 bg-white">
        {ref6InView && <ChartRow5 />}
      </div>

      <div style={{ minHeight: 300 }} ref={ref7} className="grid grid-cols-3 gap-4 mt-4">
        {ref7InView && <ChartRow6 />}
      </div>

      <div ref={ref8} style={{ minHeight: 700 }} className="p-4 mt-4 bg-white">
        {ref8InView && <PivotRow1 />}
      </div>
      {/* Disable lackof data */}
      {/* <div ref={ref9} style={{ minHeight: 800 }} className="p-4 mt-4 bg-white">
        {ref9InView && <PivotRow2 />}
      </div> */}

      <div ref={ref10} className="p-4 mt-4 bg-white">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref10InView && (
          <PivotTableTemplate
            currentPage={window?.location?.href?.split('/')[4]?.split('?')[0]}
            hideColumnSelects
          />
        )}
      </div>
    </div>
  )
}

export default Body
