import React from 'react'
import { Typography } from 'antd'
import moment from 'moment'
import { capitalizeFirstLetter } from 'Utils'

const { Text } = Typography

export const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    align: 'left',
    render: (audience) => {
      return (
        <Text className="audience-title font-bold" strong>
          {audience}
        </Text>
      )
    },
    fixed: 'left',
  },
  {
    title: 'Type',
    dataIndex: 'audience_type',
    width: 100,
    sorter: true,
    render: (tag) => capitalizeFirstLetter(tag),
  },
  {
    title: 'Last updated',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 150,
  },
  {
    title: 'Created by',
    dataIndex: 'created_by_detail',
    width: 200,
    render: (creator) => `${creator?.first_name || ''} ${creator?.last_name || ''}`,
  },
  {
    title: 'Used in',
    dataIndex: 'used_in_detail',
    align: 'left',
    width: 300,
    render: (used_in_detail) => (
      <div className="text-brand_primary font-semibold flex flex-col my-2">
        <span
          className={`${
            !used_in_detail?.active_campaign?.length ? 'text-disabled_text pointer-events-none' : ''
          }`}
        >
          {used_in_detail?.active_campaign?.length || 0} active campaigns
        </span>
        <span
          className={`${
            !used_in_detail?.pass_campaign?.length ? 'text-disabled_text pointer-events-none' : ''
          }`}
        >
          {used_in_detail?.pass_campaign?.length || 0} past campaigns
        </span>
      </div>
    ),
  },
]

export const SOURCE = [
  { label: 'Kariba', value: 'kariba' },
  // { label: 'Facebook', value: 'facebook' },
]

export const USED_IN = [
  { label: 'Active campaign', value: 'active' },
  { label: 'Inactive campaign', value: 'inactive' },
  { label: 'Not in use', value: 'not_in_use' },
]
