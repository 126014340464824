import React from 'react'
import { thousandsSeparator } from 'Utils/kFormatter'
import ChannelBadge from 'Components/ChannelBadge'
import { Empty, Popover } from 'antd'
import './index.scss'
import StatusBadge from 'Components/Charts/CateTable/Components/StatusBadge'

export type ProductItemType = {
  // TCH
  price?: string
  original_price?: string
  color?: string
  base_price?: string
  category_name?: string
  description?: string
  product_name?: string
  slug?: string
  image: string
  val?: string
  quantity?: string
  channel?: string
  drr?: number
  inventory_days?: number
  product_variant_name?: string
  supplier?: string
  cogs: number
  deliver_from: string[]
  inventory?: number
  product_mission?: string
  product_status?: string
  unit?: string
  ratio_gross_profit?: number
}

const isProduct360 = window?.location?.href?.split('/')[4]?.split('?')[0] === 'product360'

const ProductItem = ({ product, isUnit }: { product: ProductItemType; isUnit?: boolean }) => {
  return (
    <a
      href={product.slug}
      className="flex w-full text-primary_text"
      target="_self"
      style={{ cursor: isProduct360 ? 'default' : 'pointer' }}
    >
      <div className="mr-4" style={{ maxWidth: 48, height: 48 }}>
        {product.image != null ? (
          <div style={{ width: 48, height: 48 }}>
            <img src={product.image} style={{ width: 48, height: 48 }} />
          </div>
        ) : (
          <Empty
            className="mr-4 ProductItem-Empty"
            imageStyle={{ maxWidth: 48, height: 48 }}
          ></Empty>
        )}
      </div>
      <div style={{ width: '75%' }}>
        <div>{product.product_name || product.product_variant_name}</div>

        <div className="text-sm text-secondary_text">
          {product.channel && <ChannelBadge code={product.channel} />}

          {product.quantity && <span>Inventory: {product.quantity} • </span>}
          {/* {isProduct360 && <span>DRR: {product.drr?.toFixed(0)} • </span>} */}
          {/* {isProduct360 && <span>Inventory Days: {product.inventory_days?.toFixed(0)}</span>} */}
        </div>

        <div className="flex">
          <div className="flex items-center">
            <span className="font-semibold mr-1 whitespace-nowrap">
              {thousandsSeparator(parseInt(product.price || ''))} đ
            </span>
            <span className="text-sm text-secondary_text line-through">
              {product?.original_price
                ? `${thousandsSeparator(parseInt(product?.original_price || ''))} đ`
                : null}
            </span>
          </div>

          <span className="mx-2 border-r border-secondary_text"></span>
          <div className="text-secondary_text text-sm">
            {product.color && <span>{product.color} - </span>}
            {product.val && <span>Unit: {product.val}</span>}
          </div>
        </div>
        {isProduct360 && (
          <div className="flex flex-col" style={{ fontSize: 12, color: 'rgba(0,0,0,0.45)' }}>
            {product?.deliver_from && (
              <div style={{ color: '#096DD9' }}>
                {product?.deliver_from?.length <= 1 ? (
                  product?.deliver_from?.map((item, index) => {
                    return (
                      <span>
                        <span>
                          <span>{item}</span>
                        </span>
                      </span>
                    )
                  })
                ) : (
                  <div style={{ color: '#1890FF' }}>
                    <span>
                      <span>{product?.deliver_from[0]}</span>
                    </span>

                    <Popover
                      content={
                        <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                          {product?.deliver_from?.map((item, index) => {
                            return <div>{item}</div>
                          })}
                        </span>
                      }
                    >
                      <span className="ml-1 underline">{` + ${
                        product?.deliver_from?.length - 1
                      } more stores`}</span>
                    </Popover>
                  </div>
                )}
              </div>
            )}
            <div className="truncate" style={{ width: '100%' }} title={product?.supplier}>
              Supplier: {product?.supplier}
            </div>
            <div>
              <div>
                Inventory: {product?.inventory} • DRR: {product?.drr} • Inventory Days:{' '}
                {product?.inventory_days} • Unit: {product?.unit} • Giá vốn:{' '}
                {thousandsSeparator(parseInt(product?.cogs.toString()))} đ
              </div>
              <div>% Margin Profit: {product?.ratio_gross_profit} %</div>
              <div className="flex flex-row mt-1">
                <div
                  className="rounded flex items-center justify-center px-2"
                  style={{
                    backgroundColor: 'rgb(230, 247, 255)',
                    color: 'rgb(24, 144, 255)',
                    width: 'max-content',
                    fontWeight: 600,
                  }}
                >
                  {product?.product_mission}
                </div>
                <div className="ml-1">
                  <StatusBadge status={product?.product_status}></StatusBadge>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </a>
  )
}

export default ProductItem
