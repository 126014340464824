import React, { useEffect, useState } from 'react'
import { Badge } from 'antd'
import { colors } from 'DesignSystem'

import { Frequency } from 'Components/DatePicker'
import DatePicker from 'Components/DatePicker'
import DimensionFilters from 'Containers/DimensionFilters'
import { FilterIcon } from 'Components/Icon'
import { Dimension, Criteria } from 'DimensionsFiltersTypes'
import useFilters from './useFilters'
import queryString from 'query-string'

import './index.scss'
import { useHistory } from 'react-router-dom'
import { getDimensionFromUuid } from 'Services/products'
import { useStoreState } from 'easy-peasy'
import LoadingIcon from 'Components/LoadingIcon'

const FiltersHeader = ({
  hideDimensions = false,
  isUuid = false,
  loadingDefault,
}: {
  hideDimensions?: boolean
  isUuid?: boolean
  loadingDefault?: boolean
}) => {
  const { filters, onChangeFilters } = useFilters()
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [dimensionFromUuid, setDimension] = useState('')
  const history = useHistory()

  const search = queryString.parse(history.location.search)
  const filterUuidParam = search?.filter_uuid
  const [IsLoadingData, setIsLoadingData] = useState(false)
  const {
    customerGroup,
    channelGroup,
    brandGroup,
    supplierGroup,
    productCategoryGroup,
    storeGroup,
    physicalStoreGroup,
  } = useStoreState((state) => state.dimensions)
  useEffect(() => {
    async function getDimensionUuid() {
      if (filterUuidParam) {
        setIsLoadingData(true)
        try {
          const res = await getDimensionFromUuid(filterUuidParam)
          setDimension(res?.dimensions)
        } catch (err: any) {}
        setIsLoadingData(false)
      }
    }
    if (window.location.pathname.split('/')[2] == 'products') {
      !filterUuidParam &&
        history.push({
          search: queryString.stringify({
            from_date: search?.from_date?.toString() || filters?.from_date,
            to_date: search?.to_date?.toString() || filters?.to_date,
            frequency: search?.frequency?.toString() || filters?.frequency,
            filter_uuid: '',
          }),
        })
      getDimensionUuid()
    }
  }, [filterUuidParam])
  const fromDimensionsToCriteria = (dimensions: Dimension[]): Criteria[] => {
    return dimensions.map((dimension) => ({
      dimension: { ...dimension, condition: 'AND' },
      condition: dimension.condition,
    }))
  }

  const fromCriteriaToDimensions = (criteria: Criteria[]): Dimension[] => {
    return criteria.map((criterion) => ({ ...criterion.dimension, condition: criterion.condition }))
  }
  useEffect(() => {
    if (window.location.pathname.split('/')[2] != 'products') {
      history.push({
        search: queryString.stringify({
          from_date: search?.from_date?.toString() || filters?.from_date,
          to_date: search?.to_date?.toString() || filters?.to_date,
          frequency: search?.frequency?.toString() || filters?.frequency,
          dimensions: search?.dimensions?.toString() || [],
          filter_uuid: '',
        }),
      })
      setDimension('')
    }
  }, [window.location.pathname.split('/')[2]])

  const trackingLoadingFilterApi = () => {
    const getPage = window.location.pathname.split('/')[2]
    switch (getPage) {
      case 'products':
        if (
          channelGroup.length &&
          brandGroup.length &&
          productCategoryGroup.length &&
          physicalStoreGroup.length
        )
          return true
        break
      case undefined:
        return true
      case 'customers':
        return true
      case 'sale-campaigns':
        return true
      case 'marketing-campaigns':
        return true
      default:
        return false
        break
    }
  }

  return (
    <div className="AnalyticsHeader">
      {/* <div className="mb-4"> */}
      {/* <Latency /> */}
      {/* </div> */}
      <div className="flex items-center justify-end gap-2">
        <div className="mr-2">
          <div className="flex items-center gap-6">
            {!hideDimensions &&
              (trackingLoadingFilterApi() ? (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setIsPopupVisible(true)
                    // setEditingDimension(null)
                  }}
                >
                  <Badge
                    count={
                      ((filters?.dimensions &&
                        JSON.parse(filters?.dimensions?.toString())?.length) ||
                        (dimensionFromUuid &&
                          JSON.parse(dimensionFromUuid.toString())?.length)) && (
                        <div
                          style={{
                            width: 8,
                            height: 8,
                            background: colors.productColor.brand_primary,
                            borderRadius: 400,
                          }}
                        ></div>
                      )
                    }
                  >
                    <FilterIcon size={14} />
                  </Badge>
                </div>
              ) : (
                <div style={{ marginTop: '-22px' }} className="h-6 w-6 ">
                  <LoadingIcon />
                </div>
              ))}
          </div>
        </div>
        <div style={{ width: 132, height: 40 }}>
          <Frequency
            onChange={(value) =>
              onChangeFilters({
                frequency: value,
              })
            }
            value={filters.frequency}
          />
        </div>
        <div>
          <DatePicker
            onChange={({ from_date, to_date }) => {
              onChangeFilters({
                from_date: from_date,
                to_date: to_date,
              })
            }}
            value={{ from_date: filters.from_date, to_date: filters.to_date }}
          />
        </div>
      </div>
      {!hideDimensions && (
        <div
          className="p-4 mt-4 bg-white"
          style={{
            display: JSON.parse(
              (dimensionFromUuid
                ? dimensionFromUuid
                : filters?.dimensions
                ? filters?.dimensions?.toString()
                : '[]') || '[]'
            )?.length
              ? 'block'
              : 'none',
          }}
        >
          <DimensionFilters
            loadingDefault={loadingDefault}
            IsLoadingData={IsLoadingData}
            isUuid={isUuid}
            showEditButton
            list={{ customer: { page: 'analytic' }, saleChannel: { page: 'analytic' } }}
            setIsPopupVisible={setIsPopupVisible}
            isPopupVisible={isPopupVisible}
            dimensions={
              isUuid
                ? dimensionFromUuid
                  ? fromCriteriaToDimensions(JSON.parse(dimensionFromUuid.toString()))
                  : []
                : filters?.dimensions
                ? fromCriteriaToDimensions(JSON.parse(filters?.dimensions?.toString()))
                : []
            }
            onSaveDimensions={(updatedDimensions: Dimension[], filter_uuid?: string) => {
              onChangeFilters(
                !isUuid
                  ? {
                      dimensions: JSON.stringify(fromDimensionsToCriteria(updatedDimensions)),
                      filter_uuid: '',
                    }
                  : {
                      dimensions: JSON.stringify(fromDimensionsToCriteria([])),
                      filter_uuid: filter_uuid ? filter_uuid : '',
                    }
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

export default FiltersHeader
